import React from "react"
import styled from "styled-components"
import { colors } from "../../structure/theme"
import { ButtonLink } from "../../structure/theme/button.component"
import { graphql, useStaticQuery } from "gatsby"
import breakpoint from "styled-components-breakpoint"
import { MediumTitle } from "../../../components/titles.component"

const Container = styled.div`
  display: block;

  ${breakpoint(`small`)`
    display: flex;
    flex-direction: row;
  `}
`

const Half = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  :not(:first-of-type) {
    margin-top: 30px;
  }

  ${breakpoint(`small`)`
    :not(:last-of-type) {
    padding-right: 55px;
  }

  :not(:first-of-type) {
    margin-top: 0;
    border-left: 1px solid ${colors(`grey.800`)};
    padding-left: 55px;
  }
  `}
`

const Cta = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  flex-direction: column;

  ${breakpoint(`medium`)`
    flex-direction: row;
  `}
`

const Link = styled(ButtonLink).attrs({
  ghost: true,
})`
  display: inline-flex;
  text-transform: none;
  border-width: 2px;
  width: 100%;
  max-width: 240px;
  margin: auto;

  :not(:first-of-type) {
    margin-top: 20px;
  }

  ${breakpoint(`medium`)`
    width:  auto;
    max-width: 100%;
    margin: 0;
    :not(:first-of-type) {
      margin-top: 0;
    }
  `}
`

const Title = styled(MediumTitle)`
  ${breakpoint(`medium`)`
    text-align: left;
  `}
`

export default function BlockOwnerRenter () {
  const { bailleurMaison, bailleurAppartement, locataireMaison, locataireAppartement } = useStaticQuery(query)

  if (!bailleurMaison && !bailleurAppartement && !locataireMaison && !locataireAppartement) {
    return null
  }

  return (
    <Container>
      {(bailleurMaison || bailleurAppartement) && (
        <Half>
          <Title seo="h2">Vous êtes propriétaire bailleur?</Title>
          <div>
            En tant que propriétaire bailleur, vous êtes responsable de certaines réparations dans le logement que vous
            louez. HomeServe a conçu pour vous des offres adaptées à vos besoins spécifiques :
          </div>
          <Cta>
            {bailleurMaison && (
              <Link route="product" params={bailleurMaison}>
                Offre maison
              </Link>
            )}
            {bailleurAppartement && (
              <Link route="product" params={bailleurAppartement}>
                Offre appartement
              </Link>
            )}
          </Cta>
        </Half>
      )}
      {(locataireMaison || locataireAppartement) && (
        <Half>
          <Title seo="h2">Vous êtes locataire?</Title>
          <div>
            En tant que locataire, certaines pannes dans votre logement sont à votre charge. Découvrez les offres
            HomeServe pour les locataires de maison ou d’appartement :
          </div>
          <Cta>
            {locataireMaison && (
              <Link route="product" params={locataireMaison}>
                Offre maison
              </Link>
            )}
            {locataireAppartement && (
              <Link route="product" params={locataireAppartement}>
                Offre appartement
              </Link>
            )}
          </Cta>
        </Half>
      )}
    </Container>
  )
}

const query = graphql`
  query {
    bailleurMaison: product(slug: { regex: "/pack-bailleur-maison/" }) {
      slug
    }
    bailleurAppartement: product(slug: { regex: "/pack-bailleur-appartement/" }) {
      slug
    }
    locataireMaison: product(slug: { regex: "/pack-locataire-maison/" }) {
      slug
    }
    locataireAppartement: product(slug: { regex: "/pack-locataire-appartement/" }) {
      slug
    }
  }
`
